<template>
  <v-col class="px-0 py-0">
    <v-data-table
      style="margin-top: 32px"
      :headers="headers"
      :items="reports"
      hide-default-footer
      hide-default-header
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page="100"
      background-color="red"
      :loading="loading"
      no-data-text="Поки що ви не додали жодного маршруту"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            head.sortable && head.sortable !== false
              ? setSortField(head.value)
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px; text-align: center; vertical-align: middle"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.1em;
                color: #4b5262;
                user-select: none;
              "
              :style="head.sortable !== false ? 'cursor: pointer;' : ''"
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ index }">
        <div style="width: max-content; padding: 18px 0px; min-width: 50px">
          <!-- <span class="tableItem">{{ item.id }}</span> -->
          <span>{{ index + 1 }}</span>
        </div>
      </template>
      <template v-slot:[`item.route`]="{ item }">
        <div style="width: max-content">
          <span class="tableItem"
            >{{
              item?.departure?.translations.find(
                (translate) => translate.lang == "ua"
              ).name
            }}
            -
            {{
              item?.destination?.translations.find(
                (translate) => translate.lang == "ua"
              ).name
            }}</span
          >
        </div>
      </template>
      <template v-slot:[`item.order_id`]="{ item }">
        <div style="width: max-content; min-width: 140px">
          <span class="tableItem" style="color: #144fa9">{{
            item.order_ref_id
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.departure_at`]="{ item }">
        <div style="width: max-content; min-width: 140px">
          <span class="tableItem">{{
            new Date(item.schedule.departed_at).toLocaleDateString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.arrival_at`]="{ item }">
        <div style="width: max-content; min-width: 100px">
          <span class="tableItem">{{
            new Date(item.schedule.arrival_at).toLocaleDateString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              timeZone: "Europe/Kiev",
            })
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <div style="width: max-content">
          <span
            class="tableItem"
            style="color: #144fa9"
            v-if="item.user !== null"
          >
            {{ item.user?.first_name }} {{ item.user?.last_name }}
          </span>
          <span
            class="tableItem"
            style="color: #144fa9"
            v-if="item.user == null"
          >
            {{ item.tickets?.[0]?.first_name }}
            {{ item.tickets?.[0]?.last_name }}
          </span>
          <a
            :href="`/admin/worker/${item.manager?.id}`"
            v-else-if="item.manager"
          >
            <span class="tableItem" style="color: #144fa9">
              {{ item.manager?.first_name }} {{ item.manager?.last_name }}
            </span>
          </a>
        </div>
      </template>
      <template v-slot:[`item.phone_number`]="{ item }">
        <div style="width: max-content; min-width: 120px">
          <span
            class="tableItem"
            v-if="
              item.user &&
              item.user.phone_number &&
              item.user.phone_number !== 'undefined'
            "
            >{{ item.user?.phone_number }}</span
          >
          <span class="tableItem" v-if="item.user == null">{{
            item.tickets?.[0]?.phone_number
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.qt_ticket`]="{ item }">
        <div style="width: max-content; min-width: 85px">
          <span class="tableItem">{{ item.seats_count }}</span>
        </div>
      </template>
      <template v-slot:[`item.seats`]="{}">
        <div style="width: max-content; min-width: 75px">
          <span class="tableItem">Вільні</span>
        </div>
      </template>
      <template v-slot:[`item.bus`]="{ item }">
        <div style="width: max-content; min-width: 100px">
          <span class="tableItem"
            >{{ item.bus.manufacturer }} {{ item.bus.model }}</span
          >
        </div>
      </template>
      <template v-slot:[`item.purchase_at`]="{ item }">
        <div style="width: max-content; min-width: 130px">
          <span class="tableItem">{{
            item.paid_at
              ? new Date(item.paid_at).toLocaleDateString("uk-UA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  timeZone: "Europe/Kiev",
                })
              : new Date(item.created_at).toLocaleDateString("uk-UA", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  timeZone: "Europe/Kiev",
                })
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-row no-gutters justify="center">
          <div style="width: max-content; min-width: 140px">
            <div
              style="
                border-radius: 4px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400 !important;
                padding: 4px 8px;
              "
              :style="
                item.status.toLowerCase() == 'payed'
                  ? 'color:#1EB564; background: rgba(30, 181, 100, 0.1);'
                  : item.status == 'Booking' ||
                    item.status == 'PreBooking' ||
                    item.status == 'PayInBus'
                  ? 'color: #E19700; background: rgba(254, 213, 0, 0.10);'
                  : 'color:#DF3A3A;background:rgba(223, 58, 58, 0.10);'
              "
            >
              {{
                item.status.toLowerCase() == "payed"
                  ? "Оплачено"
                  : item.status == "Booking" || item.status == "PreBooking"
                  ? "Заброньовано"
                  : item.status == "PayInBus"
                  ? "Оплата при посадці"
                  : "Скасовано"
              }}
            </div>
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.bonus`]="{ item }">
        <div style="width: max-content; min-width: 150px">
          <v-row no-gutters justify="center">
            <div
              style="
                border-radius: 10px;
                background: rgba(254, 213, 0, 0.1);
                padding: 6px 12px;
                font-size: 16px;
                font-weight: 400;
                width: max-content;
              "
              v-if="item.used_bonus !== null && item.used_bonus > 0"
            >
              <span class="tableItem" style="color: #e5a928"
                >- {{ item.used_bonus }}</span
              >
            </div>
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.promocode`]="{ item }">
        <div style="width: max-content; min-width: 110px">
          <v-row no-gutters justify="center">
            <div
              style="
                border-radius: 10px;
                background: rgba(254, 213, 0, 0.1);
                padding: 6px 12px;
                color: #e5a928;
                font-size: 16px;
                font-weight: 400;
                width: max-content;
              "
              v-if="item.promocode !== null"
            >
              <span class="tableItem" style="color: #e5a928"
                >-{{ item?.promocode?.amount
                }}{{
                  item?.promocode?.amount_type == "Percent" ? "%" : "грн"
                }}</span
              >
            </div>
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.final_price`]="{ item }">
        <div style="width: max-content; min-width: 100px">
          <span class="tableItem">{{ item.final_price }} грн</span>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div style="width: max-content">
          <v-row no-gutters align="center" justify="end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="iconBackground"
                  @click="(detailOrder = item), (showDetailReportDrawer = true)"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#828282"
                    class="pointer"
                    >mdi-dots-vertical</v-icon
                  >
                </div>
              </template>
              <span>Деталі</span>
            </v-tooltip>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <detail-report-drawer
      style="margin-top: 5svh"
      v-if="showDetailReportDrawer"
      :order="detailOrder"
      @close="showDetailReportDrawer = false"
    />
  </v-col>
</template>
  
  <script>
import detailReportDrawer from "./detailReportDrawer.vue";
export default {
  components: { detailReportDrawer },
  data: () => ({
    sortBy: "",
    sortDesc: false,
    headers: [
      {
        text: "№",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Рейс", value: "route", align: "start", sortable: false },
      {
        text: "Номер квитка",
        value: "order_id",
        align: "start",
        sortable: true,
      },
      {
        text: "Дата відправлення",
        value: "departure_at",
        align: "center",
        sortable: true,
      },
      {
        text: "Дата прибуття",
        value: "arrival_at",
        align: "center",
        sortable: true,
      },
      {
        text: "Покупець",
        value: "user",
        align: "start",
        sortable: false,
      },
      {
        text: "Номер телефону",
        value: "phone_number",
        align: "center",
        sortable: false,
      },
      {
        text: "Куплено (к-сть)",
        value: "qt_ticket",
        align: "center",
        sortable: true,
      },
      {
        text: "Місця",
        value: "seats",
        align: "center",
        sortable: false,
      },
      {
        text: "Автобус",
        value: "bus",
        align: "center",
        sortable: false,
      },
      {
        text: "Дата купівлі",
        value: "purchase_at",
        align: "center",
        sortable: true,
      },
      {
        text: "Статус квитка",
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: "Списані бонуси",
        value: "bonus",
        align: "center",
        sortable: false,
      },
      {
        text: "Промокод",
        value: "promocode",
        align: "center",
        sortable: false,
      },
      {
        text: "Вартість",
        value: "final_price",
        align: "start",
        sortable: true,
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    showDetailReportDrawer: false,
    detailOrder: {},
  }),
  mounted() {
    if (this.$user_role == "dispatcher") {
      this.headers = this.headers.filter(
        (header) =>
          header.value !== "departure_at" &&
          header.value !== "arrival_at" &&
          header.value !== "qt_ticket" &&
          header.value !== "seats" &&
          header.value !== "bus" &&
          header.value !== "purchase_at" &&
          header.value !== "bonus" &&
          header.value !== "promocode" &&
          header.value !== "action"
      );
      this.headers.push({
        text: "Примітка",
        value: "note",
        align: "center",
        sortable: false,
      });
      this.headers.push({
        text: "Опції",
        value: "action",
        sortable: false,
        align: "end",
      });
    }
  },
  props: {
    reports: {
      require: true,
    },
    loading: {
      require: false,
    },
  },
  methods: {
    setSortField(value) {
      this.sortBy = value;
      this.sortDesc = !this.sortDesc;
    },
  },
  watch: {
    sortBy: {
      handler() {
        this.$emit("sortBy", this.sortBy, this.sortDesc);
      },
    },
    sortDesc: {
      handler() {
        this.$emit("sortBy", this.sortBy, this.sortDesc);
      },
    },
  },
};
</script>
  
  <style scoped>
.statusBox {
  border-radius: 10px;
  background: rgba(20, 158, 81, 0.1);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.statusName {
  color: #149e51;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  user-select: none;
  cursor: pointer;
}
.editIcon {
  width: 24px;
  height: 24px;
  background-color: #4b5262;
  -webkit-mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/editIcon.svg") no-repeat center;
  cursor: pointer;
}
.iconBackground:hover .editIcon {
  background-color: #fafafa;
}
.tableItem {
  font-size: 16px;
  font-weight: 400;
  color: #1b1b1b;
}
</style>
  <style>
.iconBackground:hover .theme--light.v-icon,
.theme--dark.v-icon {
  color: #fafafa !important;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>